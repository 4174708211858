import React from 'react';
import Layout from '../layout';
import styled from 'styled-components';
import styling from '../../styling';
import { Page } from '../layout/header';
import BookNotesOverview from './bookNotesOverview';

const StyledMain = styled.main`
  padding: 2em 0;
`;

// only render for screen readers
const StyledH1 = styled.h1`
  margin: 0;
  height: 0;
  overflow: hidden;
`;

interface Props {
  location: Location;
  data: {
    allMdx: {
      edges: {
        node: BookNotes;
      }[];
    };
  };
}

const BookNotesPage = (props: Props) => {
  const bookNotesList = props.data.allMdx.edges.map((e) => e.node);
  return (
    <Layout title="Book Notes" currentPage={Page.BookNotes} location={props.location}>
      <StyledMain>
        <StyledH1>Book Notes</StyledH1>
        {bookNotesList.map((notes) => (
          <BookNotesOverview bookNotes={notes} />
        ))}
      </StyledMain>
    </Layout>
  );
};

export default BookNotesPage;
