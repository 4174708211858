import React from 'react';
import styled from 'styled-components';
import styling from '../../styling';
import { Link } from 'gatsby';

const StyledPanel = styled.section`
  padding: 1em;
  border: 1px solid ${styling.colors.primary.normal};
  border-radius: ${styling.sizes.borderRadius};
`;

const StyledBookNotesOverview = styled(StyledPanel)`
  background-color: ${styling.colors.primary.L95};

  :not(:last-child) {
    margin-bottom: 2em;
  }

  h2 {
    margin: 0;
    font-size: 1.25em;
  }

  p {
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  color: unset;
`;

interface Props {
  bookNotes: BookNotes;
}

const BookNotesOverview = ({ bookNotes }: Props) => (
  <StyledBookNotesOverview>
    <StyledLink to={`/book-notes/${bookNotes.frontmatter.slug}`}>
      <h2>{bookNotes.frontmatter.title}</h2>
      <p>{bookNotes.frontmatter.abstract}</p>
    </StyledLink>
  </StyledBookNotesOverview>
);

export default BookNotesOverview;
