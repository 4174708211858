import { graphql } from 'gatsby';
import BookNotesPage from '../components/bookNotesList';

export default BookNotesPage;

export const query = graphql`
  {
    allMdx(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: {
        fileAbsolutePath: { regex: "/booknotes/.*/notes.md/" }
        frontmatter: { draft: { eq: false }, obsolete: { eq: false } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            finished
            draft
            obsolete
            slug
            slugSynonyms
            abstract
          }
        }
      }
    }
  }
`;
